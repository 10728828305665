<script setup>
const props = defineProps({
  theme: Object,
  heading: String,
  body: String,
  richText: String,
});

const element = ref(null);
const { toCSS } = useTheme({ element, theme: props.theme });
</script>

<template>
  <div
    class="highlighted-paragraph"
    ref="element"
    :style="{
      ...toCSS(theme),
    }"
  >
    <div class="highlighted-paragraph__inner">
      <div class="highlighted-paragraph__block">
        <h3 class="ts-h2-i">{{ heading }}</h3>
      </div>
      <div class="highlighted-paragraph__block">
        <span
          class="highlighted-paragraph__body ts-body-a"
          v-if="body"
          v-html="body"
        ></span>
        <span
          class="highlighted-paragraph__body ts-body-a"
          v-if="richText"
          v-html="richText"
        ></span>
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
.highlighted-paragraph {
  background: hsl(var(--theme-bg));
  color: hsl(var(--theme-text));

  &__inner {
    padding: units(8) var(--grid-margin);
    display: flex;
    flex-direction: column;

    @media (--md) {
      flex-direction: row;
    }
  }

  &__block {
    &:nth-child(1) {
      margin-bottom: units(2);

      @media (--md) {
        width: cols(3);
        margin-bottom: 0;
      }
    }
    &:nth-child(2) {
      @media (--md) {
        width: cols(8);
        margin-left: var(--grid-gap);
      }
    }
  }

  &__body {
    ol {
      margin: units(3) 0 units(3) units(3.5);
    }

    ul {
      margin: units(3) 0 units(3) units(3);
      list-style: square;
    }

    ol,
    ul {
      li + li {
        margin-top: units(1);
      }
    }

    u {
      background: hsl(var(--theme-text));
      color: hsl(var(--theme-bg));
      text-decoration: 0;
      padding: 0 0.2em;
    }

    table {
      width: 100%;
      border: 1px solid;
      border-collapse: collapse;
      margin: units(7) 0;

      th {
        font-family: var(--simula);
        font-size: var(--ts-h6-size);
        line-height: 1.1;
      }

      td {
        font-size: var(--ts-body-1-size);
        line-height: 1.5;
        vertical-align: top;
      }

      th,
      td {
        border-bottom: 1px solid;
        text-align: left;
        padding: units(1.6);
        font-weight: normal;

        & + th,
        & + td {
          border-left: 1px solid;
        }
      }

      tr + tr {
        border-top: 1px solid;
      }
    }
  }
}
</style>